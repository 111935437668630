import { MutableRefObject, useEffect, useState } from 'react';

export function useIsVisible(ref: MutableRefObject<HTMLDivElement | null>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const current = ref?.current;
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    current && observer?.observe(current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
