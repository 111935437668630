import React from 'react';
import { clsx } from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

type NavbarItemType = {
  text: string;
  href: string;
  handleClick: (open: boolean) => void;
  isMobileOnly?: boolean;
};

export default function NavbarItem({
  text,
  href,
  handleClick,
  isMobileOnly = false,
}: NavbarItemType) {
  const pathname = usePathname();
  let isActive;

  if (href === '/') {
    isActive = pathname === href;
  } else {
    isActive = pathname.includes(href);
  }

  return (
    <li className={clsx('md:py-4', isMobileOnly && 'md:hidden')}>
      <Link
        href={href}
        onClick={() => handleClick(false)}
        className={clsx(
          isMobileOnly && 'md:hidden',
          isActive && 'text-accent-dark',
          'hover:text-accent-dark',
          'focus:text-accent-dark',
          'flex',
          'items-center',
          'justify-between',
          'py-3.5',
          'md:py-1',
          'text-md',
          'dark:text-white',
          'px-4'
        )}
        aria-current="page"
      >
        {text}
      </Link>
    </li>
  );
}
