import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/node_modules/@next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/font/Euclid Circular B Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/font/Euclid Circular B Light Italic.ttf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../../public/font/Euclid Circular B Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/font/Euclid Circular B Italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/font/Euclid Circular B Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/font/Euclid Circular B Medium Italic.ttf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/font/Euclid Circular B SemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/font/Euclid Circular B SemiBold Italic.ttf\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../../public/font/Euclid Circular B Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/font/Euclid Circular B Bold Italic.ttf\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"euclidFonts\"}");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/public/logo.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/src/app/globals.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/src/components/layout/Nav/Navbar.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2687584880/src/website/web/src/components/shared/CookieConsent.tsx")