'use client';

import React, { useLayoutEffect, useRef, useState } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useIsVisible } from '@/hooks/useIsVisible';

type LottiePlayerType = {
  animationData: object;
  loop: boolean;
  autoPlay: boolean;
};
export const LottiePlayer = ({
  animationData,
  loop,
  autoPlay,
}: LottiePlayerType) => {
  const divRef = useRef<HTMLDivElement>(null);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const isVisible = useIsVisible(divRef);
  const [isReady, setIsReady] = useState(false);

  useLayoutEffect(() => {
    setIsReady(true);
  }, []);

  if (!isVisible && lottieRef && lottieRef.current) {
    lottieRef.current.stop();
  }

  if (isVisible && lottieRef && lottieRef.current) {
    lottieRef.current.play();
  }

  return (
    <div ref={divRef}>
      {isReady && (
        <Lottie
          lottieRef={lottieRef}
          animationData={animationData}
          loop={loop}
          autoplay={autoPlay}
          rendererSettings={{
            // viewBoxSize: '200 200 1500 1500',
            preserveAspectRatio: 'xMidYMid slice',
          }}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </div>
  );
};
