import React from 'react';
import { clsx } from 'clsx';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type MobileToggleType = {
  handleClick: () => void;
  isOpen: boolean;
};

export default function MobileToggle({
  handleClick,
  isOpen,
}: MobileToggleType) {
  return (
    <button
      onClick={handleClick}
      data-collapse-toggle="navbar-multi-level"
      type="button"
      className={clsx(
        'inline-flex',
        'items-center',
        'p-0',
        'w-10',
        'h-10',
        'justify-center',
        'text-2xl',
        'text-gray-500',
        'rounded-lg',
        'md:hidden'
      )}
      aria-controls="navbar-multi-level"
      aria-expanded="false"
    >
      <span className="sr-only">Open main menu</span>
      {!isOpen ? (
        <FontAwesomeIcon icon={faBars} />
      ) : (
        <FontAwesomeIcon icon={faXmark} />
      )}
    </button>
  );
}
