"use client";
import { FC, useState } from "react";
import * as icons from "react-icons/fa";

interface BoxProps {
  box: ColorBoxInterface
}

export const ColorBox: FC<BoxProps> = ({ box }) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  // @ts-ignore
  const IconElement = icons[box.icon];
  const boxShadow = !isHover ? `0 5px 0 0 ${box.color}` : `0 16px 24px -8px ${box.color}`

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`relative rounded-2.5xl border border-jacarta-100 bg-white p-10 dark:border-jacarta-700 dark:bg-jacarta-700`}
      style={{
        boxShadow: boxShadow,
      }}
    >
      <IconElement className="absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600" />
      <div className="mb-6 inline-flex items-center justify-center">
        <IconElement className="h-12 w-12 " style={{color: box.color}}/>
      </div>
      <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
        {box.title}
      </h3>
      <p className="dark:text-jacarta-300">
        {box.subtitle}
      </p>
    </div>
  )
}