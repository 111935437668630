'use client';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { clsx } from 'clsx';
import NavbarItem from '@/components/layout/Nav/partials/NavbarItem';
import logo from '../../../../public/logo-dark.svg';
import MobileToggle from '@/components/layout/Nav/partials/MobileToggle';
import ContactButton from '@/components/layout/Nav/partials/ContactButton';

type NavbarType = {};

export default function Navbar({}: NavbarType) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleResize = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav
      className={clsx(
        isOpen && 'h-screen',
        'border-gray-200',
        'fixed top-0 left-0 right-0',
        'top-0',
        'z-[1500]',
        'transition-all',
        'px-4',
        'lg:px-0',
        'bg-white'
      )}
    >
      <div
        className={clsx(
          'px-4',
          'md:px-8',
          'xl:px-24',
          'flex',
          'flex-wrap',
          'items-center',
          'justify-between',
          'mx-auto',
          'py-4',
          'px-0',
          'md:px-4'
        )}
      >
        <Link
          href="/"
          onClick={() => setIsOpen(false)}
          className={clsx(
            'flex',
            'items-center',
            'space-x-3',
            'rtl:space-x-reverse'
          )}
        >
          <Image
            priority
            src={logo}
            alt="1Deep Software"
            style={{ height: '32px', width: 'auto' }}
          />
        </Link>
        <div
          className={clsx(
            'flex',
            'flex-grow',
            'justify-end',
            'block',
            'md:hidden',
            'mr-4'
          )}
        >
          <ContactButton
            text={'+49 1577 681 75 43'}
            href={'tel:+49 1577 681 75 43'}
            noTextOnMobile
          />
        </div>
        <MobileToggle handleClick={handleClick} isOpen={isOpen} />
        <div
          className={clsx(
            !isOpen && 'hidden',
            'w-full',
            'md:block',
            'md:w-auto',
            'pt-32',
            'px-4',
            'md:px-0',
            'md:pt-0'
          )}
          id="navbar-multi-level"
        >
          <ul
            className={clsx(
              'flex',
              'flex-col',
              'font-light',
              'items-center',
              'text-lg',
              'md:p-0',
              'mt-4',
              'md:space-x-4',
              'lg:space-x-8',
              'md:flex-row',
              'md:mt-0'
            )}
          >
            <NavbarItem
              handleClick={setIsOpen}
              text={'Startseite'}
              href={`/`}
            />
            <NavbarItem
              handleClick={setIsOpen}
              text={'Nearshoring'}
              href={`/nearshoring`}
            />
            <NavbarItem
              handleClick={setIsOpen}
              text={'Kontakt'}
              href={`/contact`}
            />
            <ContactButton
              text={'+49 1577 681 75 43'}
              href={'tel:+49 1577 681 75 43'}
            />
          </ul>
        </div>
      </div>
    </nav>
  );
}
