import Link from 'next/link';
import { clsx } from 'clsx';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ContactButtonType = {
  text: string;
  href: string;
  noTextOnMobile?: boolean;
};

export default function ContactButton({
  text,
  href,
  noTextOnMobile = false,
}: ContactButtonType) {
  return (
    <Link
      href={href}
      className={clsx(
        'h-[39px]',
        'px-4',
        'py-2',
        'text-md',
        'text-white',
        'bg-accent',
        'hover:bg-accent-dark',
        'hover:shadow-btn',
        'active:shadow-btn-inset',
        'rounded-full',
        'transition-colors'
      )}
    >
      {text && (
        <>
          <FontAwesomeIcon icon={faPhoneVolume} size={'sm'} />{' '}
          <span
            className={clsx(
              noTextOnMobile && 'hidden',
              'ml-1',
              noTextOnMobile && 'sm:inline ',
              'ml-1'
            )}
          >
            {text}
          </span>
        </>
      )}
    </Link>
  );
}
