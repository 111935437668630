'use client';

import React, { useEffect, useState } from 'react';
import cookie from 'js-cookie';
import clsx from 'clsx';

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consentCookie = cookie.get('cookieConsent');

    if (!consentCookie) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    setShowBanner(false);
    cookie.set('cookieConsent', 'accepted', { expires: 365 });
  };

  const handleReject = () => {
    setShowBanner(false);
    cookie.set('cookieConsent', 'rejected', { expires: 365 });
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div
      className={clsx(
        'w-11/12',
        'lg:w-2/3',
        'xl:w-1/2',
        'mx-auto',
        'fixed',
        'bg-white',
        'rounded-lg',
        'left-0',
        'right-0',
        'bottom-8',
        'p-4',
        'shadow-lg'
      )}
    >
      <p>
        Diese Webseite benutzt Cookies, um Ihre Nutzererfahrung zu verbessern.
      </p>
      <p>Bitte clicken Sie auf Akzeptieren um fortzufahren.</p>
      <button
        className={clsx(
          'bg-accent',
          'hover:bg-accent-dark',
          'rounded-full',
          'py-3',
          'mt-4',
          'px-8',
          'text-center',
          'font-semibold',
          'text-white',
          'transition-all',
          'mr-4'
        )}
        onClick={handleAccept}
      >
        Akzeptieren
      </button>
      <button
        className={clsx(
          'bg-jacarta-200',
          'hover:bg-jacarta-300',
          'rounded-full',
          'py-3',
          'mt-4',
          'px-8',
          'text-center',
          'font-semibold',
          'text-white',
          'transition-all',
          'right-0'
        )}
        onClick={handleReject}
      >
        Ablehnen
      </button>
    </div>
  );
};

export default CookieConsentBanner;
